import { useEffect } from 'react'
import { signIn, useSession } from 'next-auth/react'
import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import Link from 'next/link'
import EnvironmentTag from '@/components/EnvironmentTag'

const Home: NextPage = () => {
  const session = useSession()
  const router = useRouter()

  return (
    <div className="container-wrapper">
      <div className="login-container">
        <div className="fill-half">
          <img className="push-double--top" height="175px" src="/img/ftl-logo.png" alt="" />
        </div>
        <div className="fill-half">
          <h1>Truck Dashboard</h1>
          <p className="push--bottom">Pick an option...</p>
          <div className="dashboard-row">
            <Link href="/profile">
              <div className="img-button-wrapper pointer">
                <h3>Profile</h3>
                <img src="/img/account-box.png" alt="" />
                <p className="push-half--top">Manage your public truck profile</p>
              </div>
            </Link>
            <Link href="/calendar">
              <div className="img-button-wrapper pointer">
                <h3>Calendar</h3>
                <img src="/img/calendar-month.png" alt="" />
                <p className="push-half--top">Schedule your truck for FTL events</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <EnvironmentTag />
    </div>
  )
}

export default Home
