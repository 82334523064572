import { FC } from 'react';

const EnvironmentTag: FC = () => {
  return (
    process.env.NEXT_PUBLIC_ENVIRONMENT?.toLowerCase() !== 'production' ? (
      <div className='fixed bottom-0 right-0 z-50 m-0.5 rounded bg-white px-1 py-0.5 text-xs text-black border-2 border-black'>
        {process.env.NEXT_PUBLIC_ENVIRONMENT}
      </div>
    ) : 
    (
      // return nothing if in production
      <></>
    )
  );
};

export default EnvironmentTag;
